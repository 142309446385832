@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
.App {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: white;
  font-family: "Poppins";
  top: 0px;
  right: 50%;
  transform: translateX(50%);
  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
  }
}
