@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: local("Barlow"), url("./assets/fonts/barlow-v5-latin-500.woff2") format("woff2"),
    url("./assets/fonts/barlow-v5-latin-500.woff") format("woff");
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: local("Barlow"), url("./assets/fonts/barlow-v5-latin-700.woff2") format("woff2"),
    url("./assets/fonts/barlow-v5-latin-700.woff") format("woff");
}

@font-face {
  font-family: "Paytone One";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local("Paytone One"), local("PaytoneOne"), url("./assets/fonts/paytone-one.woff") format("woff");
   /* IE6-IE8 */
  //  url('./assets/fonts/noto-sans-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Barlow';
  color: #212121;
  font-weight: 400;
  background-color: #dcdcdc;
}

#root {
  width: 100%;
  height: 100%;
}

*:focus {
  outline: none;
}

button {
  background: none;
  border: 0;
  color: inherit;
}

a {
  color: #fff;
  text-decoration: none;
  outline: none;
}

*::-webkit-scrollbar {
  display: none;
}
